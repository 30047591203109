<script>
export default {
  name: 'disable',
  props: {
    clientIds: ''
  },
  render() {
    return (
      <div>
        <span class={'choose'}>{`您确定要停用客户端 ${this.clientIds} 吗?`}</span>
      </div>
    )
  }
}
</script>

<style scoped lang="scss">
.choose{
    display: inline-block;
    width: 100%;
    margin: 10px 0;
  }
</style>