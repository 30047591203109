import Enable from './blocks/enable';
import Disable from './blocks/disable';
import Api from '@api';

export default {
  name: 'ClientSattus',
  components: {
    Enable,
    Disable
  },
  props: {
    injectData: {
      type: Object,
      default: () => {
        return {
          clientIds: []
        }
      }
    },
    type: {
      type: String,
      default: 'Enable'
    }
  },
  data() {
    return {
      enableVal: {
        IS_CLIENT:0,
        IS_CLIENT_SKU:0
      }
    }
  },
  mounted(){
  },
  methods: {
    handleConfirm() {
      if (this.type === 'Enable') {
        this.enable()
      } else {
        this.disable()
      }
    },

    /*
    *
    * */
    async enable() {
      let clientName = this.injectData.clientIds
      const params = {
        IS_CLIENT: !!this.enableVal.IS_CLIENT,
        IS_CLIENT_SKU: !!this.enableVal.IS_CLIENT_SKU,
        CLIENT_IDS: clientName
      }
      const {status, data, message} = await Api.startClientList(params);
      if (Number(status) === 200) {
        this.handleClose({...this.injectData, edit: 'enable success'})
        this.$message.success('成功')
      }
    },

    /*
    * @description 停用Client
    * */
    async disable() {
      let clientName = this.injectData.clientIds
      const params = {
        CLIENT_IDS: clientName
      }
      const {status, data, message} = await Api.stopClientList(params);
      if (Number(status) === 200) {
        this.handleClose({...this.injectData, edit: 'disable success'})
        this.$message.success('成功')
      }
    },

    handleClose(params = {}) {
      this.$emit('close', params)
    }
  }
}