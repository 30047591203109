import Api from '@/api';
import {exportByFront} from '@utils/utils';
import { PAGER } from '@constant';

export default {
  data() {
    return {
      exportParams: {},
      isRangeSelect: false,
    }
  },
  methods: {
    async exportDefine(tableName, tableFunName) {
      let column = this.tableColumnConfigList
                  .filter(item => { return !["checkbox", "seq", "edit"].includes(item.tag) })
                  .map(item => { return { label: item.title, value: item.field } });

      if (this.rangeSelectionList.length > 0 || this.selectionList.length > 0) {
        let importData = this.isRangeSelect ? this.rangeSelectionList : this.selectionList;
        exportByFront(tableName, column, importData);
      } else {
        this.exportParams.PAGE_SIZE = PAGER.MAX_EXPORT_PAGE_SIZE;
        let params = {
          //查询列表条件
          "CRITERION_BEAN_VO": this.exportParams,
          // 显示头信息
          "CRITERION_COLUMN_LIST": column.map( item => { return {code: item.value, name: item.label} }),
          // 表格名
          "EXCEL_NAME": tableName
        };
        let data = await Api[tableFunName](params);
        const url = window.URL.createObjectURL(new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', `${tableName}.xls`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    /**
     * @description 区间选择
     */
     selectCheckboxRangeEnd({records, reserves}) {
      this.selectionList = [];
      this.rangeSelectionList = records;
      this.isRangeSelect = true;
    },
    /**
     * @desc 单选
     */
    selectChangeEvent({checked, records}) {
      this.rangeSelectionList = [];
      this.selectionList = records;
      this.isRangeSelect = false;
    },
  }
}