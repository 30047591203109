import Api from '@api';
import {getFormConfig} from "./configs/formConfig";
import {getTableColumn} from './configs/tableConfig';
import {getSearchValueList, keyToConfigList, uniqueArr} from '@utils';
import {TABLE_COLUMN_COMMON} from '@constant';
import {isCanShowBlock} from '@utils/utils';
import habitMixins from '@/mixins/habit.js';
import formTableInitMixins from '@/mixins/formTableInit.js'
import exportEdit from '@/mixins/exportEdit.js';

import clientStatus from './blocks/clientStatus/clientStatus.vue';
import {cancelPendingClientData} from "../../api/client";

export default {
  name: 'Client',
  components: {
    clientStatus
  },
  provide() {
    return {
      getTableList: this.getList
    };
  },
  mixins: [habitMixins, formTableInitMixins, exportEdit],
  data: function () {
    const dataTableName = 'CLIENT';
    const formConfig = getFormConfig(this).filterFormConfig.filter(item => (item.label && isCanShowBlock('searchInfo', item.showBlock)));
    const tableColumn = getTableColumn(this).tableColumn;

    const ALL_SEARCH_ITEM_LIST = JSON.parse(window.sessionStorage.getItem('ALL_SEARCH_ITEM_LIST'))[dataTableName];
    const ALL_TABLE_COLUMNS = JSON.parse(window.sessionStorage.getItem('ALL_TABLE_COLUMNS'))[dataTableName];

    const filterFormConfig = keyToConfigList(ALL_SEARCH_ITEM_LIST, formConfig)
    const tableColumnConfigList = keyToConfigList(ALL_TABLE_COLUMNS, tableColumn, 'tag')

    return {
      formData: {}, //筛选条件
      filterFormConfig,
      conditions: formConfig, // 增加查询条件的值
      importData: [], // table 数据
      tableColumn, // 原本的表格配置
      tableColumnConfigList,
      dataTableName,
      dialog: null,
      injectData: {},
      selectionList: [],
      rangeSelectionList: [],
      editType: '',
      searchItemList: ALL_SEARCH_ITEM_LIST,
      tableColumnList: ALL_TABLE_COLUMNS,
      btnPointList:{
        clientManageClientStart:'clientManage:Client:start',
        clientManageClientStop:'clientManage:Client:stop',
        clientManageClientAdd:'clientManage:Client:add',
        clientManageClientEdit:'clientManage:Client:edit',
        clientManageClientCancelStop:'clientManage:Client:cancelStop',
        clientManageClientDelete:'clientManage:Client:delete'
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      //this.getList();
    })
  },
  methods: {
    /**
     * @description 初始化数据
     */
    async getList() {
      let CRITERION_LIST = getSearchValueList(this.conditions, this.$refs.filterForm.formData)
      let SHOW_COLUMN_LIST = this.tableColumnConfigList.map(item => item.tag).filter(item => !TABLE_COLUMN_COMMON.includes(item))
      let EXTRA_COLUMN = ['CLIENT_ID','STATUS']
      let params = {
        CRITERION_LIST: CRITERION_LIST,
        SHOW_COLUMN_LIST: uniqueArr([...SHOW_COLUMN_LIST, ...EXTRA_COLUMN]),
        CRITERION_SORT_LIST: [{"CONDITION":"CLIENT_ID", "SORT_TYPE":"asc"}],
        PAGE_NUM: await this.$refs.configTable.page.currentPage,
        PAGE_SIZE: await this.$refs.configTable.page.pageSize
      };
      this.exportParams = params;
      this.initFormData = this.$refs.filterForm.formData;
      const {status, data, message} = await Api.GetClientList(params);
      if (status === 200) {
        this.importData = data.list
        this.$refs.configTable.page.total = Number(data.total)
      }
    },
    /**
     * @description 表单编辑时候的跳转
     */
    clientEdit(params, type = 'query') {
      this.injectData = {...params};
      this.$router.push({path: '/clientDetail', query: {CLIENT_ID: this.injectData.CLIENT_ID, _type: type}})
    },
    /*
    * @description 启用Client
    * */
    startClient(clients = []) {
      this.editType = 'Enable'
      this.openStartStopModal(clients)
    },
    /*
  * @description 停用Client
  * */
    stopClient(clients) {
      this.editType = 'Disable'
      this.openStartStopModal(clients)
    },
    /**
     * @description 取消停用
     */
    async cancelStopClient(row) {
      const params = {
        CLIENT_IDS:[row.CLIENT_ID]
      }
      const {data,status,message} = await cancelPendingClientData(params)
      if(status === 200){
        this.$message.success('成功')
        this.getList()
      }
    },
    openStartStopModal(clients) {
      if (!clients.length) return this.$message.warning('未选择客户端')
      this.injectData.clientIds = clients.map(item => item.CLIENT_ID)
      this.dialog = 'clientStatus'
    },
    /**
     * @description 清除表单和父子间的数据
     */
    dialogClosed(params) {
      let edit = params.edit || '';
      if (edit && ['enable success', 'disable success'].includes(edit)) {
        this.getList()
      }
      this.dialog = null;
      this.injectData = {};
    },
    /**
     * @description 双击行打开详情
     */
    handleDBClick(row) {
      this.clientEdit(row, 'query')
    },
    /**
     * @description 导出
     */
    handleExport() {
      this.exportDefine('客户端列表', 'ExportClientData')
    },
  }
}