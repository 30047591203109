<template>
  <div class="enable">
    <el-checkbox v-model="info.IS_CLIENT" :true-label="1" :false-label="0" @change="setEnableVal">仅启用客户端</el-checkbox>
    <el-checkbox v-model="info.IS_CLIENT_SKU" :true-label="1" :false-label="0" @change="setEnableVal">启用客户端以及相关SKU</el-checkbox>
  </div>
</template>

<script>
export default {
  name: 'enable',
  props: {
    enableVal:{
      type: Object,
      default:()=>{
        return {
          IS_CLIENT:0,
          IS_CLIENT_SKU:0
        }
      }
    }
  },
  data() {
    return {
      info:{
        IS_CLIENT:0,
        IS_CLIENT_SKU:0
      }
    }
  },
  created() {
    this.info = this.enableVal
  },
  methods:{
    setEnableVal(){
      this.$emit('input', this.info)
    }
  }
}
</script>

<style scoped lang="scss">
.enable{
  .choose{
    display: inline-block;
    width: 100%;
    margin: 10px 0;
  }
}
</style>