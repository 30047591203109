import Api from '@api';

import { YN, CLIENT_STATUS, ClientTimeUnit, PAGER, DISALLOW_SHORT_SHIP_OPTIONS, TIME_UOMS } from '@constant';
import {createTimeoutPromise, dateFormat} from "@utils/utils";
import {setSelectPalletList} from "../../../api/common";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}



export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [
      {
        "key": "NAME",
        "label": "名称",
        "type": "autoComplete",
        "placeholder": "请输入名称",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CONTACT",
        "label": "联系人",
        "type": "autoComplete",
        "placeholder": "请输入联系人",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ADDRESS1",
        "label": "地址1",
        "type": "autoComplete",
        "placeholder": "请输入地址1",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CONTACT_PHONE",
        "label": "电话",
        "type": "autoComplete",
        "placeholder": "请输入电话",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ADDRESS2",
        "label": "地址2",
        "type": "autoComplete",
        "placeholder": "请输入地址2",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CONTACT_FAX",
        "label": "传真",
        "type": "autoComplete",
        "placeholder": "请输入传真",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "TOWN",
        "label": "城镇",
        "type": "autoComplete",
        "placeholder": "请输入城镇",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
      },
      {
        "key": "CONTACT_EMAIL",
        "label": "电子邮件",
        "type": "autoComplete",
        "placeholder": "请输入电子邮件",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "COUNTY",
        "label": "县/市",
        "type": "autoComplete",
        "placeholder": "请输入县/市",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CONTACT_MOBILE",
        "label": "手机",
        "type": "autoComplete",
        "placeholder": "请输入手机",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "POSTCODE",
        "label": "邮编",
        "type": "autoComplete",
        "placeholder": "请输入邮编",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        defaultUpper:true,
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "URL",
        "label": "URL",
        "type": "autoComplete",
        "placeholder": "请输入URL",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "COUNTRY",
        "label": "国家/地区",
        "type": "multiselect",
        "placeholder": "请选择国家/地区",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        valueType:'SINGLE_VALUE',
        options: async () => {
          const {status, data: { list } } = await Api.GetPageTableColumnItem({...defaultPaginationInfo});
          if (Number(status) === 200) {
            return list.map(item=>{
              return{
                key:item.ISO3_ID,
                label:item.TANDATA_ID
              }
            })
          }
        },
      },
      {
        "key": "GLN",
        "label": "GLN",
        "type": "autoComplete",
        "placeholder": "请输入GLN",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "VAT_NUMBER",
        "label": "VAT Number",
        "type": "autoComplete",
        "placeholder": "请输入VAT Number",
        "tabGroup": "mqs_client_tab0",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DISALLOW_SHORT_SHIP",
        "label": "不允许短运",
        "type": "multiselect",
        "placeholder": "请选择不允许短运",
        "tabGroup": "mqs_client_tab1",
        "tableName":'CLIENT',
        "showBlock": [ "all" ],
        "checked": false,
        "options": DISALLOW_SHORT_SHIP_OPTIONS,
        "formatter": ({cellValue, row, column}) => {
          let res = DISALLOW_SHORT_SHIP_OPTIONS.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "MAX_RETURN_DAYS",
        "label": "Maximum Return Days",
        "type": "numrange",
        "placeholder": "请选择Maximum Return Days",
        "tabGroup": "mqs_client_tab1",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "TRAX_USE_ADDRESS",
        "label": "Trax Use Address",
        "type": "select",
        "placeholder": "请选择Trax Use mqs_client_tab0",
        "tabGroup": "mqs_client_tab1",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "CREATED_BY",
        "label": "创建人",
        "type": "autoComplete",
        "placeholder": "请输入创建人",
        "tabGroup": "mqs_client_tab1",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CREATION_DATE",
        "label": "创建日期",
        "type": "date",
        "placeholder": "请输入创建日期",
        "tabGroup": "mqs_client_tab1",
        "tableName":'CLIENT',
        "showBlock": ["all"],
        "checked": false
      },
      {
        "key": "LAST_UPDATED_BY",
        "label": "最后更新人",
        "type": "autoComplete",
        "placeholder": "请输入最后更新人",
        "tabGroup": "mqs_client_tab1",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LAST_UPDATE_DATE",
        "label": "最后更新日期",
        "type": "date",
        "placeholder": "请输入最后更新日期",
        "tabGroup": "mqs_client_tab1",
        "tableName":'CLIENT',
        "showBlock": ["all"],
        "checked": false
      },
      {
        "key": "SERIAL_NUMBER_PREFIX",
        "label": "序列号前缀",
        "type": "autoComplete",
        "placeholder": "请输入序列号前缀",
        "tabGroup": "mqs_client_tab3",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SERIAL_NUMBER_SUFFIX",
        "label": "序列号后缀",
        "type": "autoComplete",
        "placeholder": "请输入序列号后缀",
        "tabGroup": "mqs_client_tab3",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SERIAL_NUMBER_START",
        "label": "序列号开始",
        "type": "numrange",
        "placeholder": "请选择序列号开始",
        "tabGroup": "mqs_client_tab3",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SERIAL_NUMBER_END",
        "label": "序列号结束",
        "type": "numrange",
        "placeholder": "请选择序列号结束",
        "tabGroup": "mqs_client_tab3",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SERIAL_NUMBER_NEXT",
        "label": "下一个序列号",
        "type": "numrange",
        "placeholder": "请选择下一个序列号",
        "tabGroup": "mqs_client_tab3",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SERIAL_NUMBER_USE_KITTING",
        "label": "套件是否用序列号",
        "type": "select",
        "placeholder": "请选择套件是否用序列号",
        "tabGroup": "mqs_client_tab3",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "SERIAL_UNIQUENESS",
        "label": "序列号是否唯一",
        "type": "select",
        "placeholder": "请选择序列号是否唯一",
        "tabGroup": "mqs_client_tab3",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "SERIAL_TYPE_LINK_PREFIX",
        "label": "序列号类型关联前缀",
        "type": "autoComplete",
        "placeholder": "请输入序列号类型关联前缀",
        "tabGroup": "mqs_client_tab3",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        defaultUpper:true
      },
      {
        "key": "CLIENT_ID",
        "label": "客户端ID",
        "type": "magnifier",
        "placeholder": "请输入客户端",
        "tabGroup": "THead",
        "tableName":'CLIENT',
        defaultUpper:true,
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CLIENT_GROUP",
        "label": "客户端组",
        "type": "multiselect",
        "placeholder": "请选择客户端组",
        "tabGroup": "THead",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        valueType:'SINGLE_VALUE',
        options: async () => {
          let { status, data: { list }, message } = await Api.GetClientGroup({
            ...defaultPaginationInfo
          });

          if (Number(status) === 200) {
            return list.map(item=>{
              return{
                key:item.clientGroup,
                label:item.clientGroup
              }
            })
          }
        }
      },
      {
        "key": "AUDIT_ENABLED",
        "label": "审计启用",
        "type": "select",
        "placeholder": "请选择审计启用",
        "tabGroup": "THead",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "STATUS",
        "label": "状态",
        "type": "multiselect",
        "placeholder": "请选择状态",
        "tabGroup": "THead",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        valueType:'SINGLE_VALUE',
        options: CLIENT_STATUS,
        formatter: ({cellValue, row, column}) => {
          let res = CLIENT_STATUS.filter(item => {
            return item.key === cellValue
          })

          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "NOTES",
        "label": "注释",
        "type": "textarea",
        "placeholder": "请输入注释",
        "tabGroup": "THead",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "col": '2',
        "checked": false
      },
      {
        "key": "ASN_PREFIX",
        "label": "ASN前缀",
        "type": "autoComplete",
        "placeholder": "请输入ASN前缀",
        "tabGroup": "mqs_client_asn_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ASN_DATE_FORMAT",
        "label": "ASN日期格式",
        "type": "multiselect",
        "placeholder": "请选择日期格式",
        "tabGroup": "mqs_client_asn_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        valueType:'SINGLE_VALUE',
        options: ClientTimeUnit
      },
      {
        "key": "ASN_PREFIX_START",
        "label": "ASN 序号开始值",
        "type": "numrange",
        "placeholder": "请选择序号开始值",
        "tabGroup": "mqs_client_asn_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ASN_PREFIX_NEXT",
        "label": "ASN 下一个序号",
        "type": "numrange",
        "placeholder": "请选择下一个序号",
        "tabGroup": "mqs_client_asn_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ASN_PREFIX_END",
        "label": "ASN 序号结束值",
        "type": "numrange",
        "placeholder": "请选择序号结束值",
        "tabGroup": "mqs_client_asn_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ASN_PREFIX_LAST_DATE",
        "label": "ASN 最新日期",
        "type": "date",
        "placeholder": "请选择最新日期",
        "tabGroup": "mqs_client_asn_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ASN_PREFIX_RESET",
        "label": "ASN 重设序号",
        "type": "select",
        "placeholder": "请选择重设序号",
        "tabGroup": "mqs_client_asn_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "ORDER_PREFIX",
        "label": "订单前缀",
        "type": "autoComplete",
        "placeholder": "请输入订单前缀",
        "tabGroup": "mqs_client_tab4",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        defaultUpper:true,
      },
      {
        "key": "ORDER_DATE_FORMAT",
        "label": "订单日期格式",
        "type": "autoComplete",
        "placeholder": "请输入日期格式",
        "tabGroup": "mqs_client_tab4",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ORDER_PREFIX_START",
        "label": "订单 序号开始值",
        "type": "autoComplete",
        "placeholder": "请输入序号开始值",
        "tabGroup": "mqs_client_tab4",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ORDER_PREFIX_NEXT",
        "label": "订单 下一个序号",
        "type": "autoComplete",
        "placeholder": "请输入下一个序号",
        "tabGroup": "mqs_client_tab4",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ORDER_PREFIX_END",
        "label": "订单 序号结束值",
        "type": "autoComplete",
        "placeholder": "请输入序号结束值",
        "tabGroup": "mqs_client_tab4",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ORDER_PREFIX_RESET",
        "label": "订单 重设序号",
        "type": "autoComplete",
        "placeholder": "请输入重设序号",
        "tabGroup": "mqs_client_tab4",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "ORDER_PREFIX_LAST_DATE",
        "label": "订单 最新日期",
        "type": "autoComplete",
        "placeholder": "请输入最新日期",
        "tabGroup": "mqs_client_tab4",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DEMAND_ORDER_PREFIX",
        "label": "需求订单前缀",
        "type": "autoComplete",
        "placeholder": "请输入需求订单前缀",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'需求订单编号',
        "showBlock": [
          "all"
        ],
        "checked": false,
        defaultUpper:true,
      },
      {
        "key": "DEMAND_ORDER_DATE_FORMAT",
        "label": "需求订单 日期格式",
        "type": "autoComplete",
        "placeholder": "请输入日期格式",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'需求订单编号',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DEMAND_ORDER_PREFIX_START",
        "label": "需求订单 序号开始值",
        "type": "autoComplete",
        "placeholder": "请输入序号开始值",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'需求订单编号',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DEMAND_ORDER_PREFIX_NEXT",
        "label": "需求订单 下一个序号",
        "type": "autoComplete",
        "placeholder": "请输入下一个序号",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'需求订单编号',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DEMAND_ORDER_PREFIX_END",
        "label": "需求订单 序号结束值",
        "type": "autoComplete",
        "placeholder": "请输入序号结束值",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'需求订单编号',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DEMAND_ORDER_PREFIX_LAST_DATE",
        "label": "需求订单 最新日期",
        "type": "autoComplete",
        "placeholder": "请输入最新日期",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'需求订单编号',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DEMAND_ORDER_PREFIX_RESET",
        "label": "需求订单 重设序号",
        "type": "autoComplete",
        "placeholder": "请输入重设序号",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'需求订单编号',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DECAT_ORDER_PREFIX",
        "label": "目录订单前缀",
        "type": "autoComplete",
        "placeholder": "请输入目录订单前缀",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'Demand Decatalogued Order ID',
        "showBlock": [
          "all"
        ],
        "checked": false,
        defaultUpper:true
      },
      {
        "key": "DECAT_ORDER_DATE_FORMAT",
        "label": "目录订单 日期格式",
        "type": "autoComplete",
        "placeholder": "请输入日期格式",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'Demand Decatalogued Order ID',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DECAT_ORDER_PREFIX_START",
        "label": "目录订单 序号开始值",
        "type": "autoComplete",
        "placeholder": "请输入序号开始值",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'Demand Decatalogued Order ID',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DECAT_ORDER_PREFIX_NEXT",
        "label": "目录订单 下一个序号",
        "type": "autoComplete",
        "placeholder": "请输入下一个序号",
        "tabGroup": "mqs_client_tab5",
        "tabGroupChild":'Demand Decatalogued Order ID',
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DECAT_ORDER_PREFIX_END",
        "label": "目录订单 序号结束值",
        "type": "autoComplete",
        "placeholder": "请输入序号结束值",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'Demand Decatalogued Order ID',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DECAT_ORDER_PREFIX_LAST_DATE",
        "label": "目录订单 最新日期",
        "type": "autoComplete",
        "placeholder": "请输入最新日期",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'Demand Decatalogued Order ID',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "DECAT_ORDER_PREFIX_RESET",
        "label": "目录订单 重设序号",
        "type": "autoComplete",
        "placeholder": "请输入重设序号",
        "tabGroup": "mqs_client_tab5",
        "tableName":'CLIENT',
        "tabGroupChild":'Demand Decatalogued Order ID',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PRE_ADVICE_PREFIX",
        "label": "预通知前缀",
        "type": "autoComplete",
        "placeholder": "请输入预通知前缀",
        "tabGroup": "mqs_client_preadv_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        defaultUpper:true
      },
      {
        "key": "PRE_ADVICE_DATE_FORMAT",
        "label": "预通知 日期格式",
        "type": "autoComplete",
        "placeholder": "请输入日期格式",
        "tabGroup": "mqs_client_preadv_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PRE_ADVICE_PREFIX_START",
        "label": "预通知 序号开始值",
        "type": "autoComplete",
        "placeholder": "请输入序号开始值",
        "tabGroup": "mqs_client_preadv_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PRE_ADVICE_PREFIX_NEXT",
        "label": "预通知 下一个序号",
        "type": "autoComplete",
        "placeholder": "请输入下一个序号",
        "tabGroup": "mqs_client_preadv_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PRE_ADVICE_PREFIX_END",
        "label": "预通知 序号结束值",
        "type": "autoComplete",
        "placeholder": "请输入序号结束值",
        "tabGroup": "mqs_client_preadv_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PRE_ADVICE_PREFIX_RESET",
        "label": "预通知 重设序号",
        "type": "autoComplete",
        "placeholder": "请输入重设序号",
        "tabGroup": "mqs_client_preadv_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "PRE_ADVICE_PREFIX_LAST_DATE",
        "label": "预通知最新日期",
        "type": "autoComplete",
        "placeholder": "请输入最新日期",
        "tabGroup": "mqs_client_preadv_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "UPI_PREFIX",
        "label": "UPI前缀",
        "type": "autoComplete",
        "placeholder": "请输入",
        "tabGroup": "mqs_client_upi_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        defaultUpper:true,
      },
      {
        "key": "UPI_DATE_FORMAT",
        "label": "UPI 日期格式",
        "type": "autoComplete",
        "placeholder": "请输入",
        "tabGroup": "mqs_client_upi_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "UPI_PREFIX_START",
        "label": "UPI 序号开始值",
        "type": "autoComplete",
        "placeholder": "请输入",
        "tabGroup": "mqs_client_upi_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "UPI_PREFIX_NEXT",
        "label": "UPI 下一个序号",
        "type": "autoComplete",
        "placeholder": "请输入",
        "tabGroup": "mqs_client_upi_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "UPI_PREFIX_END",
        "label": "UPI 序号结束值",
        "type": "autoComplete",
        "placeholder": "请输入",
        "tabGroup": "mqs_client_upi_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "UPI_PREFIX_RESET",
        "label": "UPI 重设序号",
        "type": "autoComplete",
        "placeholder": "请输入",
        "tabGroup": "mqs_client_upi_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "UPI_PREFIX_LAST_DATE",
        "label": "UPI最新日期",
        "type": "autoComplete",
        "placeholder": "请输入",
        "tabGroup": "mqs_client_upi_prefix_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CURRENCY",
        "label": "货币",
        "type": "multiselect",
        "placeholder": "请选择货币",
        "tabGroup": "mqs_tab_third_party_billing",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        valueType:'SINGLE_VALUE',
        options: async () => {
          const { status, data: { list } } = await Api.GetAbCurrency({ ...defaultPaginationInfo });
          if (status === 200) {
            return list.map(item => {
              return { label: item.DESCRIPTION, key: item.CURRENCY }
            })
          }
        },
      },
      {
        "key": "VOLUME_UOM",
        "label": "体积",
        "type": "autoComplete",
        "placeholder": "请输入体积",
        "tabGroup": "mqs_tab_third_party_billing",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "WEIGHT_UOM",
        "label": "重量",
        "type": "autoComplete",
        "placeholder": "请输入重量",
        "tabGroup": "mqs_tab_third_party_billing",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LENGTH_UOM",
        "label": "长度",
        "type": "autoComplete",
        "placeholder": "请输入长度",
        "tabGroup": "mqs_tab_third_party_billing",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "AREA_UOM",
        "label": "面积",
        "type": "multiselect",
        "placeholder": "请选择面积",
        "tabGroup": "mqs_tab_third_party_billing",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": [

        ]
      },
      {
        "key": "TIME_UOM",
        "label": "Time_Uom",
        "type": "select",
        "placeholder": "请输入Time_Uom",
        "tabGroup": "mqs_tab_third_party_billing",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": TIME_UOMS,
        "formatter": ({cellValue, row, column}) => {
          let res = TIME_UOMS.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "NUMBER_UOM",
        "label": "Number_Uom",
        "type": "multiselect",
        "placeholder": "请选择Number_Uom",
        "tabGroup": "mqs_tab_third_party_billing",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        valueType:'SINGLE_VALUE',
        options: async () => {
          let {status, data: {list}, message} = await setSelectPalletList({
            PALLET_CONFIG: {},
            ...defaultPaginationInfo
          });

          if (Number(status) === 200) {
            return list.map(item=>{
              return{
                key:item.PALLET_CONFIG,
                label:item.PALLET_CONFIG
              }
            })
          }
        },
      },
      {
        "key": "Max_Age_Days",
        "label": "剩余保质天数",
        "type": "numrange",
        "placeholder": "请选择剩余保质天数",
        "tabGroup": "mqs_tab_third_party_billing",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SSCC_TAG_ID",
        "label": "是否用标签号",
        "type": "select",
        "placeholder": "请选择是否用标签号",
        "tabGroup": "mqs_client_sscc_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "SSCC_CONTAINER_ID",
        "label": "是否用容器号",
        "type": "select",
        "placeholder": "请选择是否用容器号",
        "tabGroup": "mqs_client_sscc_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "SSCC_PALLET_ID",
        "label": "是否用托盘号",
        "type": "select",
        "placeholder": "请选择是否用托盘号",
        "tabGroup": "mqs_client_sscc_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "SSCC_SERIAL_START",
        "label": "SSCC序号开始",
        "type": "numrange",
        "placeholder": "请选择SSCC序号开始",
        "tabGroup": "mqs_client_sscc_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SSCC_SERIAL_NEXT",
        "label": "SSCC下一个序号",
        "type": "numrange",
        "placeholder": "请选择SSCC下一个序号",
        "tabGroup": "mqs_client_sscc_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SSCC_SERIAL_END",
        "label": "SSCC序号结束",
        "type": "numrange",
        "placeholder": "请选择SSCC序号结束",
        "tabGroup": "mqs_client_sscc_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SSCC_COMPANY_PREFIX",
        "label": "SSCC公司前缀",
        "type": "autoComplete",
        "placeholder": "请输入SSCC公司前缀",
        "tabGroup": "mqs_client_sscc_tab",
        "tableName":'CLIENT',
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "EXTRACT_FILE_SEQUENCE",
        "label": "",
        "type": "numrange",
        "placeholder": "请选择",
        "tableName":'CLIENT',
        "showBlock": [

        ],
        "checked": false
      },
      {
        "key": "SUPPLIER_REQUIRED",
        "label": "",
        "type": "select",
        "placeholder": "请选择",
        "tableName":'CLIENT',
        "showBlock": [

        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "TM_MOVE_STATUS",
        "label": "",
        "type": "autoComplete",
        "placeholder": "请输入",
        "tableName":'CLIENT',
        "showBlock": [

        ],
        "checked": false
      },
      {
        "key": "UPLOADED_CUSTOMS",
        "label": "",
        "type": "select",
        "placeholder": "请选择",
        "tableName":'CLIENT',
        "showBlock": [

        ],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }
    ]
  }
}

