import {getFormConfig} from "./formConfig";
import { isCanShowBlock } from '@utils/utils';

export const getTableColumn = vm => {
  const formConfigList = getFormConfig().filterFormConfig.filter(item => item.label && isCanShowBlock('tableList', item.showBlock));
  const list = formConfigList.map(item => {
    return {
      title: item.label,
      field: item.key,
      sortable: true,
      tag: item.key,
      formatter: item.formatter
    }
  })

  const fixedList = [
    { type: 'seq', title: '序号', width: '60', tag: 'seq' },
    { type: 'checkbox', title: '全选/反选', width: '120', tag: 'checkbox' },
  ];

  const opList = [
    {
      field: "edit",
      title: "操作",
      fixed: 'right',
      width: '200',
      tag: 'edit',
      _renderType: true,
      render: (h, {data}) => {
        let { row } = data;
        return (
          <div class={'edit'}>
            {
              row.STATUS === 'Disabled' && vm.$_has(vm.btnPointList.clientManageClientStart) ?  <el-button type="text" onClick={() => { vm.startClient([{...row}]) }}>启用</el-button> : ''
            }
            {
              row.STATUS === 'Pending' && vm.$_has(vm.btnPointList.clientManageClientCancelStop) ? <el-button type="text" onClick={() => { vm.cancelStopClient({...row}) }}>取消停用</el-button> : ''
            }
            {
              row.STATUS === 'Able' && vm.$_has(vm.btnPointList.clientManageClientStop) ?  <el-button type="text" onClick={() => { vm.stopClient([{...row}]) }}>停用</el-button> : ''
            }
            <el-button type="text" onClick={() => { vm.clientEdit(row, 'query') }}>详情</el-button>
            <el-button type="text" onClick={() => { vm.clientEdit(row, 'edit') }}>修改</el-button>
          </div>
        );
      }
    },
  ]
  return {
    tableColumn:[...fixedList, ...list, ...opList]
  }
}